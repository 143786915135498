import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import { useNavigate } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import toast, { Toaster } from 'react-hot-toast'
function Notification() {
  const navigate = useNavigate()

  const handleAddButtonClick = () => {
    navigate('/notification/add')
  }
  const [data, setData] = useState({
    columns: [
      {
        Header: 'Message',
        accessor: 'message',
        align: 'left',
      },
      {
        Header: 'Total User',
        accessor: 'user_count',
        align: 'left',
      },
      {
        Header: 'Users',
        accessor: 'users',
        align: 'left',
      },
      {
        Header: 'Notification Tag',
        accessor: 'notification_tag',
        align: 'left',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        align: 'left',
      },
    ],
    rows: [],
  })

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/get-user-notifications`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        toast.error('The user information is not found.')
      }

      const jsonData = await response.json()

      const rows = jsonData.data.map((user) => ({
        notification_tag: user.notification_tag,
        message: user.message,
        user_count: user.user_ids.length,
        users: (
          <MDBox
            sx={{
              maxHeight: 150, // Adjust max height as needed
              overflowY: 'auto', // Allows scrolling if the content exceeds max height
            }}
          >
            {user.userDetails.map((item) => (
              <List key={item._id}>
                {' '}
                {/* Ensure _id is unique */}
                <ListItem style={{ color: 'rgb(5 110 17)' }}>
                  <ListItemIcon style={{ color: ' rgb(5 110 17)', width: 1 }}>
                    <Icon fontSize="medium">person</Icon>
                  </ListItemIcon>
                  {/* Ensure the field you're trying to display exists */}
                  <ListItemText primary={item.address || item.name || 'Unknown'} /> {/* Replace 'address' if needed */}
                </ListItem>
              </List>
            ))}
          </MDBox>
        ),
        created_at: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
              {formatDate(user.created_at)}
            </MDTypography>
          </MDBox>
        ),
      }))

      setData((prevData) => ({
        ...prevData,
        rows: rows,
      }))
    } catch (error) {
      console.log('Error fetching data:', error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Notification List
                </MDTypography>
                <MDButton variant="gradient" bgColor="info" color="dark" size="large" onClick={handleAddButtonClick}>
                  Send Notification
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable table={data} isSorted={true} entriesPerPage={true} showTotalEntries={true} canSearch={true} noEndBorder />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default Notification
