// Material Dashboard 2 React layouts
import Dashboard from 'layouts/dashboard'
import User from 'layouts/user'
import Payment from 'layouts/payment'
import SignIn from 'layouts/authentication/sign-in'
import UserView from 'layouts/user/view'
import UserReview from 'layouts/user/under-review'
import Resubmission from 'layouts/user/resubmission'
import Rejected from 'layouts/user/rejected'
import CreateNft from 'layouts/user/create-nft'
import Completed from 'layouts/user/completed'
import Block from 'layouts/user/block'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
// @mui icons
import Notification from 'layouts/notification'
import NotificationAdd from 'layouts/notification/add'
import Coupon from 'layouts/coupon'
import CouponAdd from 'layouts/coupon/add'
import Icon from '@mui/material/Icon'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin'
const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Users',
    key: 'user',
    icon: <Icon fontSize="small">people</Icon>,
    route: '/user',
    component: <User />,
    items: [
      {
        name: 'Under Review User',
        route: '/user/under-review',
        key: 'underreview',
        component: <UserReview />,
      },
      {
        name: 'Create NFT Users',
        route: '/user/create-nft',
        key: 'createnft',
        component: <UserReview />,
      },
      { name: 'Block Users', route: '/user/block', key: 'block', component: <UserReview /> },
      { name: 'Resubmission Users', route: '/user/resubmission', key: 'resubmission', component: <UserReview /> },
      { name: 'Rejected Users', route: '/user/rejected', key: 'rejected', component: <UserReview /> },
      { name: 'Completed Users', route: '/user/completed', key: 'completed', component: <UserReview /> },
    ],
  },
  {
    type: 'sub',
    name: 'Review',
    key: 'review',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/user/under-review',
    component: <UserReview />,
  },
  {
    type: 'sub',
    name: 'Create NFT',
    key: 'nft',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/user/create-nft',
    component: <CreateNft />,
  },
  {
    type: 'sub',
    name: 'Block',
    key: 'blockuser',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/user/block',
    component: <Block />,
  },

  {
    type: 'sub',
    name: 'Resubmission',
    key: 'resubmissionuser',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/user/resubmission',
    component: <Resubmission />,
  },
  {
    type: 'sub',
    name: 'Rejected',
    key: 'rejecteduser',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/user/rejected',
    component: <Rejected />,
  },
  {
    type: 'sub',
    name: 'Completed',
    key: 'completeduser',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/user/completed',
    component: <Completed />,
  },

  {
    type: 'sub',
    name: 'View User',
    key: 'user_view',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/user/view/:id',
    component: <UserView />,
  },
  {
    type: 'collapse',
    name: 'Payment Request',
    key: 'payment',
    icon: <CurrencyBitcoinIcon />,
    route: '/payment',
    component: <Payment />,
  },
  {
    type: 'collapse',
    name: 'Notifications',
    key: 'notifications',
    icon: <Icon> notifications </Icon>,
    route: '/notifications',
    component: <Notification />,
  },
  {
    type: 'sub',
    name: 'Send Notification',
    key: 'send-notifications',
    icon: <Icon> notifications </Icon>,
    route: '/notification/add',
    component: <NotificationAdd />,
  },

  {
    type: 'collapse',
    name: 'Coupons',
    key: 'coupons',
    icon: <LocalOfferIcon />,
    route: '/coupons',
    component: <Coupon />,
  },
  {
    type: 'sub',
    name: 'Add Coupons',
    key: 'coupons-add',
    icon: <LocalOfferIcon />,
    route: '/coupon/add',
    component: <CouponAdd />,
  },

  {
    type: 'auth',
    name: 'Sign In',
    key: 'sign-in',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/admin/sign-in',
    component: <SignIn />,
  },
]

export default routes
