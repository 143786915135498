import { useState } from 'react'
import PropTypes from 'prop-types'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import { collapseItem, collapseIconBox, collapseIcon, collapseText } from 'examples/Sidenav/styles/sidenavCollapse'
import { useMaterialUIController } from 'context'
import List from '@mui/material/List'
import { NavLink } from 'react-router-dom'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

function SidenavCollapse({ icon, name, items, active, ...rest }) {
  const [controller] = useMaterialUIController()
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller

  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItem component="li">
        {/* If name is 'Users', wrap MDBox with ListItemButton */}
        {name === 'Users' ? (
          <MDBox
            {...rest}
            onClick={handleClick}
            sx={(theme) =>
              collapseItem(theme, {
                active,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              })
            }
          >
            <ListItemIcon sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })}>
              {typeof icon === 'string' ? <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon> : icon}
            </ListItemIcon>
            <ListItemText
              primary={name}
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  active,
                })
              }
            />

            {open ? <ExpandLess /> : <ExpandMore />}
          </MDBox>
        ) : (
          <MDBox
            {...rest}
            sx={(theme) =>
              collapseItem(theme, {
                active,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              })
            }
          >
            <ListItemIcon sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })}>
              {typeof icon === 'string' ? <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon> : icon}
            </ListItemIcon>
            <ListItemText
              primary={name}
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  active,
                })
              }
            />
          </MDBox>
        )}
      </ListItem>

      {name === 'Users' && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div">
            {items.map((item, index) => (
              <NavLink key={item.key} to={item.route}>
                <MDBox
                  sx={(theme) =>
                    collapseItem(theme, {
                      transparentSidenav,
                      whiteSidenav,
                      darkMode,
                      sidenavColor,
                    })
                  }
                >
                  <ListItemIcon sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })}>
                    <ArrowForwardIcon sx={(theme) => collapseIcon(theme, { active })} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={(theme) =>
                      collapseText(theme, {
                        miniSidenav,
                        transparentSidenav,
                        whiteSidenav,
                        active,
                      })
                    }
                  />
                </MDBox>
              </NavLink>
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

SidenavCollapse.defaultProps = {
  active: false,
  items: [],
}

SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  items: PropTypes.array,
}

export default SidenavCollapse
