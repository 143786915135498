import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDSelect from 'components/MDSelect'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import Icon from '@mui/material/Icon'
import React, { useEffect, useState, useCallback } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import toast, { Toaster } from 'react-hot-toast'
import MDInput from 'components/MDInput'
function CreateNft() {
  const token = localStorage.getItem('userToken')
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [user_id, setUserId] = useState('')
  const [description, setDescription] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  const handleClickOpen = (user) => {
    setUserId(user._id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleSelectChange = (value) => {
    setSelectedStatus(value)

    if (value === 'Under_review') {
      setNotificationMessage('We are reviewing your documents.')
    } else if (value === 'Processing') {
      setNotificationMessage('We have received your documents and are processing them.')
    } else if (value === 'Block') {
      setNotificationMessage('Your account has been blocked by the administrator.')
    } else if (value === 'Resubmission') {
      setNotificationMessage('You must submit the document again.')
    } else if (value === 'Rejected') {
      setNotificationMessage('Unfortunately, your document has not met the required standards and has been rejected.')
    } else if (value === 'Completed') {
      setNotificationMessage('We’re excited to let you know that your new NFT has been successfully created!')
    }
  }

  const [data, setData] = useState({
    columns: [
      {
        Header: 'Wallet Address',
        accessor: 'address',
        align: 'left',
      },
      {
        Header: 'created At',
        accessor: 'createdAt',
        align: 'left',
      },
      { Header: 'current-status', accessor: 'currentstatus', align: 'left' },

      { Header: 'status', accessor: 'status', align: 'center' },
      { Header: 'action', accessor: 'action', align: 'center' },
    ],
    rows: [],
  })

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/getUserList/Processing`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        toast.error('The user information is not found.')
      }
      const jsonData = await response.json()

      const rows = jsonData.data.user.map((users) => ({
        address: users.address ? users.address : '-',
        createdAt: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
              {formatDate(users.createdAt)}
            </MDTypography>
          </MDBox>
        ),
        currentstatus: users.status ? (users.status === 'Pending' ? 'Under Review' : users.status === 'Processing' ? 'Create NFT' : users.status) : '-',
        status: (
          <>
            <MDButton variant="contained" onClick={() => handleClickOpen(users)} color="primary">
              Change Status
            </MDButton>
          </>
        ),
        action: (
          <MDTypography
            component="a"
            href={`view/${users._id}`}
            sx={{
              backgroundColor: '#0e9003cc',
              color: '#ffffff',
              padding: '8px',
              borderRadius: '4px',
              marginRight: '10px',
            }}
          >
            <Icon>visibility</Icon>
          </MDTypography>
        ),
      }))
      setData((prevData) => ({
        ...prevData,
        rows: rows,
      }))
    } catch (error) {
      console.log('Error fetching data:', error.message)
      setError('Error fetching data')
    }
  }, [token])

  const onClickSubmit = async (event) => {
    event.preventDefault()
    const bdy = { status: selectedStatus, description: description, notificationMessage: notificationMessage }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/updateUserStatus/${user_id}`, {
        method: 'POST',
        body: JSON.stringify(bdy),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        toast.success('Status updated Successfully')
        setSuccess('Status updated Successfully')
        fetchData()
        handleClose()
      } else {
        const errorData = await response.json()
        toast.error('Unknown error occurred')
        setError(errorData.message || 'Unknown error occurred') // Extract the message
      }
    } catch (error) {
      toast.error('Upload failed. Please try again.')
      const errorMessage = error.message || 'Upload failed. Please try again.'

      setError(errorMessage)
    }
  }
  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccess(null)
        setError(null)
      }, 4000)

      return () => clearTimeout(timer)
    }
  }, [success, error])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  User List
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <DataTable table={data} isSorted={true} entriesPerPage={true} showTotalEntries={true} canSearch={true} noEndBorder />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: onClickSubmit, // Form submission handler
        }}
      >
        <DialogTitle>Update User Status</DialogTitle>
        <DialogContent>
          <DialogContentText mb={4}>Determine which status to change for the user.</DialogContentText>
          <MDSelect value={selectedStatus} onChange={handleSelectChange} />
          <MDBox mb={2} mt={4} p={1}>
            <MDInput type="text" label="Addition Description" variant="standard" fullWidth name="description" onChange={(e) => setDescription(e.target.value)} />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  )
}

export default CreateNft
